import React, { createContext } from 'react'
import addParametersToUrl from '../helpers/addParametersToUrl'

const defaultState = {
  open: false,
  toggleModal: () => {},
}

const QuoteFormContext = createContext(defaultState)

class QuoteFormProvider extends React.Component {

  constructor() {
    super()

    this.state = {
      open: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal(newState) {
    const { open } = this.state
    addParametersToUrl('quoteForm', 'opened');
    this.setState({ open: newState ?? !open })
  }

  render() {
    const { children } = this.props
    const { open } = this.state

    return (
      <QuoteFormContext.Provider
        value={{
          open,
          toggleModal: this.toggleModal,
        }}
      >
        {children}
      </QuoteFormContext.Provider>
    )
  }
}

export default QuoteFormContext
export { QuoteFormProvider }
